<div class="sidenav-header w-100">
  <img
  [src]="dp()"
  (error)="onImgError()"
  loading="lazy"
  alt="profile"
  [width]="profilePicSize()"
  [height]="profilePicSize()" />
  <div class="header-text" [class.hide-header-text]="sideNavCollapsed() || isMobileView()">
    <h2 class="text-wrap prevent-select" title="{{display_name}}">{{display_name | string:'capital'}}</h2>
    <p  class="text-wrap prevent-select" title="{{role}}">{{role | string:'capital'}}</p>
  </div>
</div>

@if (showNav()) {
  <mat-nav-list class="w-100" >
    @for (item of menuItems(); track item.label) {
      @if (item.access | module: item.access_label) {
        <app-menu-item [item]="item"  [collasped]="sideNavCollapsed()" [isMobileView]="isMobileView()"/>
      }
    }
  </mat-nav-list>
}@else {
  <div class="ms-3 me-3">
    <button class="btn btn-outline-dark w-100 mb-4 mt-4 main-height" (click)="goback()">
      <i class="bi bi-chevron-left"></i>
      Back
    </button>
    <h5>
      Setting
    </h5>
  </div>
  <mat-nav-list class="w-100" >
    @for (item of settingItems(); track item.label) {
      <app-menu-item [item]="item" [collasped]="sideNavCollapsed()" [isMobileView]="isMobileView()"/>
    }
  </mat-nav-list>
}

