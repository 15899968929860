<footer class="footer">
  <div class="footer-contain">
    <div class="footer-logo">
      <img src="images/logo_white.png" class="footer-logo-img links" alt="pic" [routerLink]="['/']">
    </div>
    <div class="footer-links-contain">
      <div class="footer-links">
        <a class="links" href="https://dartle.app/about.html">About us</a>
        <div [routerLink]="['/privacy']" class="links">Privacy Policy</div>
        <div [routerLink]="['/eula']" class="links">End user license agreement</div>
        <!-- <div [routerLink]="['/']" class="links">Licenses</div> -->
        <!-- <div [routerLink]="['/help']" class="links">Help & Support</div> -->
        <div [routerLink]="['/terms']" class="links">Terms & Conditions</div>
      </div>
      <div>
        <span class="text-muted text-center text-sm-left d-block d-sm-inline-block white" translate>Copyright © {{year}}. <a
            href="https://dartle.app/" target="_blank">Dartle</a> Version: {{version}}   All rights reserved.</span>
      </div>
    </div>
  </div>
</footer>
