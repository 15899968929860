/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { MainComponent } from "./modules/main/main.component";
import { AuthenticationService } from './services/authentication.service';
import { CLASS, UserSuperData } from './data-models/user-data';
import { StorageService } from './services/storage.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, MainComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'dartle';
  classe_name: UserSuperData | undefined;
  lessonPlan: any;
  activity: any;

  constructor(
    public auth: AuthenticationService,
    private storage: StorageService
  ){}

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.auth.updatePermissionsForUser();

    // When role data in local storage changes, refresh user permissions
    window.addEventListener('onRoleUpdate', () => {
      this.auth.updatePermissionsForUser();
    });
  }

  setClasseName(classe_name: UserSuperData) {
    this.storage.setJsonValue(CLASS, classe_name)
    this.classe_name = classe_name
  }

  getClasseName() {
    return this.storage.getJsonValue(CLASS)
  }

  setLesson(lesson: any) {
    this.lessonPlan = lesson
  }

  getLesson() {
    return this.lessonPlan
  }

  setActivity(activity: any) {
    this.activity = activity
  }

  getActivity() {
    return this.activity
  }
}
